<template>
    <div class="container">
		<div class="iconBox">
			<img :src="iconSrc" style="width: 100%; height: 100%" alt="">
		</div>
		<div class="itemName">{{inputData.deviceName}}</div>
		<div class="totalCountBox">
			<active-number class="totalCount" :number="totalCount"></active-number>
		</div>
		<div class="normalTextBox" style="left: 8.89%; top: 47.06%">
			<span class="normalText" style="color: #fff">正常</span>
			<span class="normalText" style="margin-left: 2.22%; color: #63e7b7">{{inputData.normal}}</span>
		</div>
		<div class="normalTextBox" style="left: 42.22%; top: 47.06%">
			<span class="normalText" style="color: #fff">预警</span>
			<span class="normalText" style="margin-left: 2.22%; color: #ff3b3b">{{inputData.warning}}</span>
		</div>
		<div class="normalTextBox" style="left: 8.89%; bottom: 11.76%">
			<span class="normalText" style="color: #fff">故障</span>
			<span class="normalText" style="margin-left: 2.22%; color: #fff">{{inputData.breakdown}}</span>
		</div>
		<div class="normalTextBox" style="left: 42.22%; bottom: 11.76%">
			<span class="normalText" style="color: #fff">离线</span>
			<span class="normalText" style="margin-left: 2.22%; color: #ccc">{{inputData.offline}}</span>
		</div>
	</div>
</template>

<style lang="less" scoped>
.container {
	height: 100%;
	position: relative;
	border: #ffd03b 1px solid;

	.iconBox {
		position: absolute;
		right: 5%;
		bottom: 15%;
		width: 17.72%;
		height: 49.02%;
	}

	.itemName {
		position: absolute;
		left: 8.89%;
		top: 11.76%;
		height: 2.04vh;
		color: #fff;
		font-family: 'pingfangM', sans-serif;
		font-size: 1.48vh;
		line-height: 2.04vh;
	}
	.totalCountBox {
		position: absolute;
		right: 8.89%;
		top: 10.78%;
		height: 26.47%;

		.totalCount {
			height: 2.5vh;
			font-size: 2.31vh;
			font-family: 'titleFont', sans-serif;
			line-height: 2.5vh;
			background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
	.normalTextBox {
		position: absolute;
		height: 20.59%;
		width: 100%;
		display: flex;
		align-items: center;
		.normalText {
			height: 1.944vh;
			font-size: 1.3vh;
			line-height: 1.944vh;
			font-family: 'pingfangSc', sans-serif;
		}
	}

}
</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props:{
		inputData: {
			type: Object
		}
	},
	components: {
		activeNumber
	},
	data(){
		return {
			totalCount: 0,
			iconSrc: ''
		}
	},
	mounted(){
		this.initData();
		let ctx = require.context('@/assets/devices',false,/.png$/);
		this.iconSrc = ctx(this.inputData.iconUrl);
		return false;
	},
	methods: {
		initData(){
			let d = this.inputData;
			if(this.$c.inspection){
				//显示迎检数据
				this.totalCount = d.inspectionData;
			}else{
				// 显示真实数据
				this.totalCount = d.normal + d.warning + d.breakdown + d.offline;
			}
			
		}
	},
	watch:{
		inputData(){
			this.initData();
		}
	}
}
</script>

